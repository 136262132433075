import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { BottomSheetItem } from '@components/BottomSheetItem';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "bottomsheetitem"
    }}>{`BottomSheetItem`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={BottomSheetItem} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<BottomSheetItem iconName=\"bin\" title=\"Delete\" loading />\n<BottomSheetItem iconName=\"close\" title=\"Close\" divider={false} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      BottomSheetItem,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <BottomSheetItem iconName="bin" title="Delete" loading mdxType="BottomSheetItem" />
    <BottomSheetItem iconName="close" title="Close" divider={false} mdxType="BottomSheetItem" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      